<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="onAddNewCat" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

                <div class="table-container">
                    <b-table
                        :fields="tableFields"
                        :items="budgetForm"
                        responsive="true"
                        bordered
                        head-variant="light"
                        no-border-collapse
                    >
                        <template #top-row="data">
                            <td class="td-numbering"></td>
                            <td class="td-numbering table-success">1</td>
                            <td class="td-numbering table-danger">2</td>
                            <td class="td-numbering table-info">3</td>
                            <td class="td-numbering table-primary">4</td>
                            <td class="td-numbering table-warning">5</td>
                            <td class="td-numbering table-success">6</td>
                            <td class="td-numbering table-danger">7</td>
                            <td class="td-numbering"></td>
                        </template>
                        <template #head(action)>
                            <div class="text-center">
                                <b-form-checkbox
                                    v-model="selectAll"
                                    @change="e => setIsAllDelete(e)"
                                    :value="true"
                                    :unchecked-value="false" />
                            </div>
                        </template>
                        <template #head(more)>
                            <div class="text-center">
                                <i
                                    :title="getCommonText('del_selected')" 
                                    class="icon icon-close table-all-remove"
                                    @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                            </div>
                        </template>
                        <template #cell(action)="data">
                            <b-form-checkbox
                                v-model="data.item.itemToDelete"
                                @input="e => { if (!e) selectAll = false; }"
                                :value="true"
                                :unchecked-value="false" />
                        </template>
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(branches)="data">
                            <div
                                class="column-width-100 bp-category-name">
                                <div v-if="lng === 'ru'">{{ data.item['branches'] }} </div>
                                <div v-else>{{ data.item['branches_' + lng] }} </div>
                                <i 
                                    v-if="variantAttribute"
                                    class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                                    @click="onEditCatName(data.item)"
                                ></i>
                            </div>
                        </template>
                        <template #cell(patients_count)="data">
                            <b-form-input v-if="variantAttribute"
                                          class="text-right"
                                          :value="data.item.patients_count"
                                          @change="v => data.item.patients_count = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, '^[0-9]+$')"
                                          @blur="inputFixedVldtn(data.item, 'patients_count', data.item.patients_count, 0)">
                            </b-form-input>
                            <div v-else>{{ data.value }}</div>
                            <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.patients_count === null || data.item.patients_count === '' || data.item.patients_count <= 0)">{{ getCommonText("positive") }}</p>
                        </template>
                        <template #cell(treatment_cost)="data">
                            <b-form-input v-if="variantAttribute"
                                          class="text-right"
                                          :value="data.item.treatment_cost"
                                          @change="v => data.item.treatment_cost = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                          @blur="inputFixedVldtn(data.item, 'treatment_cost', data.item.treatment_cost, 2)">
                            </b-form-input>
                            <div v-else>{{ data.value }}</div>
                            <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.treatment_cost === null || data.item.treatment_cost === '' || data.item.treatment_cost <= 0)">{{ getCommonText("positive") }}</p>
                        </template>
                        <template #cell(average_day)="data">
                            <b-form-input v-if="variantAttribute"
                                          class="text-right"
                                          :value="data.item.average_day"
                                          @change="v => data.item.average_day = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                          @blur="inputFixedVldtn(data.item, 'average_day', data.item.average_day, 2)">
                            </b-form-input>
                            <div v-else>{{ data.value }}</div>
                            <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.average_day === null || data.item.average_day === '' || data.item.average_day <= 0)">{{ getCommonText("positive") }}</p>
                        </template>
                        <template #cell()="data">
                            <div class="text-right">{{ $n(data.value) }}</div>
                        </template>
                        <template #cell(files)="data">
                            <span
                                class="blue pointer underline"
                                @click="onFilesClick(data.item)"
                            >({{data.item.files}})</span>
                        </template>
                        <template #cell(more)="data">
                            <div class="text-center">
                                <i 
                                    title="Удалить запись" 
                                    class="icon icon-clear table-remove" 
                                    v-if="variantAttribute" 
                                    @click="deleteItemWithAttachedFiles(`${getDecText('del_curr_rec')}`, data.item, data.index)"
                                ></i>
                            </div>
                        </template>
                        <template #bottom-row="data">
                            <td class="text-right" colspan="3">{{ getCommonText("total") }}</td>
                            <td class="text-right">{{ isNaN(patientsCount) ? 0 : $n(patientsCount) }}</td>
                            <td colspan="2"></td>
                            <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                            <td colspan="2"></td>
                        </template>
                    </b-table>
                </div>
                <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText("add") }}</span>
        </div>
        <files-updown :header="header"
                        :variant-attribute="variantAttribute"
                        :load="load"
                        @getFiles="getFiles"
                        @getNewFiles="getNewFiles($event)"
                        @delFile="delFile($event)"
                        ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="budgetForm"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addItem"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";

export default {
    name: 'Form02_142',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        CSetBFCategoryName
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '02-142',
                name_kk: 'Стационарлық денсаулық сақтау мекемелерiндегi дәрi-дәрмектерге арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов на медикаменты в стационарных учреждениях здравоохранения'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            categoryFieldNames: {ru: "branches", kk: "branches_kk"}
        };
    },
    methods: {
        addItem(nameRu, nameKk) {
            const that = this;
            const item = { 
                id: this.newRowStartId,
                branches: nameRu,
                branches_kk: nameKk
            };
            this.newRowStartId--;
            this.itemUpdate(item);
            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = that.number(item.patients_count) * that.number(item.treatment_cost) * that.number(item.average_day) / 1000;
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            this.budgetForm.push(item);
        },

        async itemUpdate(item) {
            this.$set(item, 'patients_count', 0);
            this.$set(item, 'treatment_cost', 0);
            this.$set(item, 'average_day', 0);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },
        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
            }
            await values.forEach(val => {
                const item = {
                    id: val.id,
                    branches: val.branches,
                    branches_kk: val.branches_kk,
                    patients_count: val.patients_count,
                    treatment_cost: val.treatment_cost,
                    average_day: val.average_day,
                    files: val.files,
                    row_files: val.row_files,
                    itemToDelete: false,
                };
                Object.defineProperty(item, 'total', {
                    get: function () {
                        const total = that.number(item.patients_count) * that.number(item.treatment_cost) * that.number(item.average_day) / 1000;
                        return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                    }
                });
                this.budgetForm.push(item);
            });
            this.load = false;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                if (this.isSavingForbidden(row, this.tableFields, ['patients_count', 'treatment_cost', 'average_day'], [])) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'branches', row.branches);
                this.$set(item, 'branches_kk', row.branches_kk);
                this.$set(item, 'patients_count', parseInt(row.patients_count));
                this.$set(item, 'treatment_cost', parseFloat(row.treatment_cost));
                this.$set(item, 'average_day', parseFloat(row.average_day));
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'total', row.total);
                values.push(item);
            }
            if (values.length && this.variantAttribute) {
                this.save(values);
            }
            else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values, error) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getCommonText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },
        patientsCount() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.patients_count);
            }
            return sum;
        },
        getFormText() {
            return this.setFormText('form_n_142.');
        },
        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'index',
                        label: ' № '
                    },
                    {
                        key: 'branches',
                        label: this.getFormText('section_name')
                    },
                    {
                        key: 'patients_count',
                        label: this.getFormText('treated_patient_count')
                    },
                    {
                        key: 'treatment_cost',
                        label: this.getFormText('treatment_price')
                    },
                    {
                        key: 'average_day',
                        label: this.getFormText('avg_bed_day_count')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('med_expense')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }

    }
};
</script>

<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .filter-actions-flex {
        display: flex;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
</style>